import request from "@/utils/request";

// 获取法语一对一老师列表
export function TeacherList(params) {
    return request({
        url: '/admin/base/oneCourseTearcherList',
        method: 'get',
        params
    })
}

// 添加一对一老师
export function addTeacherList(data) {
    return request({
        url: '/admin/base/oneCourseAddTearcher',
        method: 'post',
        data
    })
}

// 编辑一对一老师
export function editTeacherList(data) {
    return request({
        url: '/admin/base/oneCourseUpdateTearcher',
        method: 'post',
        data
    })
}

// 删除一对一老师
export function delTeacherList(data) {
    return request({
        url: '/admin/base/oneCourseDelTearcher',
        method: 'delete',
        data
    })
}
