<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="老师">
          <el-select
            v-model="queryForm.teacher_id"
            placeholder="请选择老师"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.user_id"
              v-for="item in teacherList"
              :key="item.user_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="queryForm.state"
            placeholder="请选择状态"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in stateList"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            placeholder="请输入手机号码"
            v-model="queryForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="courseAppointmentList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="用户名" prop="userInfo.nickname" align="center"></el-table-column>
        <el-table-column label="备注" prop="order_remark" align="center"></el-table-column>
        <el-table-column label="预约章节" prop="note" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.note == ''">无</div>
            <div v-else>{{scope.row.note}}</div>
          </template>
        </el-table-column>
        <el-table-column label="预约开始时间" prop="start_time" align="center"></el-table-column>
        <el-table-column label="预约结束时间" prop="end_time" align="center"></el-table-column>
        <el-table-column label="报名课程" prop="goodsInfo.goods_name" align="center"></el-table-column>
        <el-table-column label="预约老师" prop="teacherInfo.name" align="center"></el-table-column>
        <el-table-column label="状态" prop="state" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1 && scope.row.timestamp > timestamp" style="color:#FF951E">待审核</div>
            <div v-if="scope.row.state == -1" style="color:#FE5050">预约失败</div>
            <div v-if="scope.row.state == 2" style="color:#50C46F">预约成功</div>
            <div v-if="scope.row.state == -2" style="color:000000">取消预约</div>
            <div v-if="scope.row.state == 1 && scope.row.timestamp <= timestamp" style="color:#AFAFAF">已过期</div>
            <!-- <el-button
              type="warning"
              icon="el-icon-message-solid"
              circle
              @click="editGoods(scope.row)"
              size="small"
              v-if="scope.row.state == 1"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-check"
              circle
              @click="title(2)"
              size="small"
              v-if="scope.row.state == 2"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-close"
              circle
              @click="title(-1)"
              size="small"
              v-if="scope.row.state == -1"
            ></el-button> -->
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

     <!-- 操作弹窗 -->
      <el-dialog title="是否接受预约？" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form ref="addForm">
        <el-form-item el-form-item label="原因" label-width="100px">
            <el-input
              v-model="reason"
              placeholder="请输入原因"
            />
        </el-form-item>
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" @click="confirm" >接受</el-button>
           <el-button type="primary" @click="cancel">拒绝</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {
  getCourseAppointment,
  editCourseAppointment,
} from "@/api/onetooneAppointment.js";
import {TeacherList} from "@/api/onetooneTeacher.js";
import store from "@/store";
export default {
  data() {
    return {
      addDialog: false,
      times: null,
      queryForm: {
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
        type: 3,
        teacher_id: null,
        state: null,
        phone: "",
      },
      courseAppointmentList: [],
      total: 0,
      state: null,
      reason: "",
      id: null,
      timestamp: "",
      teacherList: [],
      stateList: [
        {key:1,value:"待审核"},
        {key:2,value:"预约成功"},
        {key:-1,value:"预约失败"},
        {key:-2,value:"取消预约"},
        // {key:-9,value:"已过期"},
      ]
    };
  },

  created() {
    this.timestamp = new Date().getTime()
    this.getAppointmentList();
    this.getTeacherList();
  },
  methods: {
    //   获取老师列表
    getTeacherList() {
      TeacherList({type:3}).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.teacherList = res.data.data;
      });
    },

    //预约列表
    getAppointmentList() {
      getCourseAppointment(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        res.data.data.forEach(element => {
          element.timestamp = new Date(element.start_time).getTime();
        });
        this.total = res.data.total;
        this.courseAppointmentList = res.data.data;
      });
    },

    // 点击编辑图标
    editGoods(item) {
      console.log(item)
      this.addDialog = true;
      this.id = item.id;
    },

    // 点击接受预约
    confirm() {
      let confirmData = {
        id: this.id,
        state: 2,
        reason: this.reason
      }
      console.log(confirmData)
      editCourseAppointment(confirmData).then((res)=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("您已接受预约！");
        this.addDialog = false;
        this.getAppointmentList();
        store.commit("changeNumFrench", 0);
      })
    },
    // 点击拒绝预约
    cancel(){
      if(this.reason == ""){
        this.$message.error("请输入拒绝理由");
        return;
      }
      let cancelData = {
        id: this.id,
        state: -1,
        reason: this.reason
      }
      console.log(cancelData)
      editCourseAppointment(cancelData).then((res)=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("您已拒绝预约！");
        this.addDialog = false;
        this.getAppointmentList();
        store.commit("changeNumFrench", 0);
      })
    },
    // 提醒
    title(value){
      if(value == 2){
        this.$message.info("您已接受预约，不能再修改！");
      }else if(value == -1){
        this.$message.info("您已拒绝预约，不能再修改！");
      }
    },
    // 弹窗关闭事件
    closed() {
      this.reason = ""
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getAppointmentList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getAppointmentList() ;
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getAppointmentList() ;
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  img {
    width: 120px;
    height: 200px;
  }
}

.tips {
  color: red;
}
::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
