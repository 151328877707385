import request from "@/utils/request";

//一对一预约列表
export function getCourseAppointment(data) {
    return request({
        url: '/admin/base/oneCourseAppointments',
        method: 'get',
        params: data
    })
}

//一对一预约操作
export function editCourseAppointment(data) {
    return request({
        url: '/admin/base/oneCourseAppointmentEdit',
        method: 'post',
        data
    })
}